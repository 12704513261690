import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Spring Boot integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spring-boot-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#spring-boot-integration",
        "aria-label": "spring boot integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Spring Boot integration`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#integrating-with-spring-mvc-with-tomcat"
          }}>{`Integrating with Spring MVC (with Tomcat)`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#integrating-with-spring-boot"
          }}>{`Integrating with Spring Boot`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#internal-services"
          }}>{`Internal services`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#documentation-service"
            }}>{`Documentation service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#health-check"
            }}>{`Health check`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#collecting-metrics"
            }}>{`Collecting metrics`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#actuator-support"
            }}>{`Actuator support`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#other-bean-configurations"
          }}>{`Other bean configurations`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#typearmeriaserverconfigurator-vs-consumerserverbuilder"
            }}>{`type://ArmeriaServerConfigurator vs Consumer<ServerBuilder>`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#typedependencyinjector"
            }}>{`type://DependencyInjector`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://spring.io/"
      }}>{`Spring`}</a>{` framework provides powerful features necessary for building web applications
such as dependency injection, data binding, AOP and transaction management.
By integrating your Spring application with Armeria, you can serve both legacy Spring services
and gRPC or Thrift services on the same port. Additionally, you can gradually migrate your services to Armeria
for improved performance.`}</p>
    <p>{`Armeria provides a simple way to integrate legacy Spring Boot applications with just a few lines of code.
Furthermore, Armeria supports several internal services for monitoring and management purposes,
which can be conveniently configured through beans.`}</p>
    <h2 {...{
      "id": "integrating-with-spring-mvc-with-tomcat",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-with-spring-mvc-with-tomcat",
        "aria-label": "integrating with spring mvc with tomcat permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating with Spring MVC (with Tomcat)`}</h2>
    <Tip mdxType="Tip">
      <p>{`  Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/tree/main/examples/spring-boot-tomcat"
        }}>{`armeria-examples/spring-boot-tomcat`}</a>{` to
find a fully working example.`}</p>
    </Tip>
    <p>{`You can integrate your Spring MVC with Armeria using `}<a parentName="p" {...{
        "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
      }}>{`type://TomcatService`}</a>{`.
First, add the following dependency to your application.`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-spring-boot3-starter'
    }, {
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-tomcat10'
    }]} mdxType="RequiredDependencies" />
    <p>{`Serve the embedded `}<a parentName="p" {...{
        "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
      }}>{`type://TomcatService`}</a>{` via Armeria by using the `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` bean.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`import com.linecorp.armeria.server.tomcat.TomcatService;
import com.linecorp.armeria.spring.ArmeriaServerConfigurator;
import org.apache.catalina.connector.Connector;
import org.springframework.boot.web.embedded.tomcat.TomcatWebServer;
import org.springframework.boot.web.servlet.context.ServletWebServerApplicationContext;
import org.springframework.context.annotation.Bean;
import org.springframework.context.annotation.Configuration;

@Configuration
public class ArmeriaConfiguration {
   public static Connector getConnector(ServletWebServerApplicationContext applicationContext) {
       final TomcatWebServer container = (TomcatWebServer) applicationContext.getWebServer();
       container.start();
       return container.getTomcat().getConnector();
   }

   @Bean
   public TomcatService tomcatService(ServletWebServerApplicationContext applicationContext) {
       return TomcatService.of(getConnector(applicationContext));
   }

   @Bean
   public ArmeriaServerConfigurator armeriaServerConfigurator(TomcatService tomcatService) {
       return sb -> sb.serviceUnder("/", tomcatService);
   }
}
`}</code></pre>
    <p>{`Add the following properties to your `}<inlineCode parentName="p">{`application.yml`}</inlineCode>{` (or `}<inlineCode parentName="p">{`application.properties`}</inlineCode>{`) file.
This configuration will prevent the exposure of the embedded Tomcat service and instead expose your Armeria service.
You can explore additional configuration options in `}<a parentName="p" {...{
        "href": "type://ArmeriaSettings:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaSettings.html"
      }}>{`type://ArmeriaSettings`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`# Prevent the embedded Tomcat from opening a TCP/IP port.
server:
  port: -1

armeria:
  ports:
    - port: 8080
      protocols:
        - HTTP
`}</code></pre>
    <p>{`With this simple configuration, everything is set up. Now you can serve your legacy Spring service through Armeria.
Although it may not seem like much has changed compared to before, your application is now ready to serve both RPC services
(such as gRPC or Thrift) and RESTful services via Armeria.`}</p>
    <h2 {...{
      "id": "integrating-with-spring-boot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#integrating-with-spring-boot",
        "aria-label": "integrating with spring boot permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integrating with Spring Boot`}</h2>
    <Tip mdxType="Tip">
      <p>{`  Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples/tree/main/spring-boot-minimal"
        }}>{`armeria-examples/spring-boot-minimal`}</a>{` to
find a fully working example.`}</p>
    </Tip>
    <p>{`Even if you don't utilize Spring MVC based on web servlets like Tomcat, there is still significant value in combining Spring and Armeria.
You can leverage the automatic configuration and dependency injection features of Spring Boot to develop an Armeria application.`}</p>
    <p>{`First, you need the `}<inlineCode parentName="p">{`armeria-spring-boot3-starter`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-spring-boot3-starter'
    }]} mdxType="RequiredDependencies" />
    <p>{`Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service"
      }}>{`annotated service`}</a>{` is similar to a Spring controller.
You can declare the annotated service as a bean and inject it into the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`.
Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=TodoAnnotatedService.java",
        "filename": "TodoAnnotatedService.java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.server.annotation.*;

import org.springframework.beans.factory.annotation.Autowired;
import org.springframework.stereotype.Component;

import java.util.List;

// Make this annotated service a bean.
@Component
@PathPrefix("/todos")
public class TodoAnnotatedService {

    private final TodoRepository todoRepository;

    // Automatically injected by Spring
    @Autowired
    public TodoAnnotatedService(TodoRepository todoRepository) {
        this.todoRepository = todoRepository;
    }

    @Get("/:id")
    public HttpResponse get(@Param Integer id) {
        Todo todo = todoRepository.get(id);
        if (todo == null) {
            return HttpResponse.of(HttpStatus.NO_CONTENT);
        }
        return HttpResponse.ofJson(todo);
    }

    @Post
    public HttpResponse create(Todo todo) {
        final int result = todoRepository.create(todo);
        if (result == 0) {
            return HttpResponse.of(HttpStatus.INTERNAL_SERVER_ERROR);
        }
        return HttpResponse.of(HttpStatus.CREATED);
    }
}
`}</code></pre>
    <p>{`To inject the annotated service into the `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` bean, you can follow this approach:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public ArmeriaServerConfigurator armeriaServerConfigurator(TodoAnnotatedService todoAnnotatedService) {
   return serverBuilder -> {
       serverBuilder.serviceUnder("/docs", new DocService())
                    .decorator(LoggingService.newDecorator())
                    .annotatedService("/api", todoAnnotatedService);
   };
}
`}</code></pre>
    <p>{`The following configuration prevents the execution of Spring's embedded web server and instead runs Armeria.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`# See https://docs.spring.io/spring-boot/docs/current/reference/html/howto.html#howto.webserver.disable.
# The application should not run as a web application and should not start an embedded web server.
spring.main.web-application-type: none

armeria:
  ports:
    - port: 8080
      protocols:
        - http
`}</code></pre>
    <h2 {...{
      "id": "internal-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#internal-services",
        "aria-label": "internal services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Internal services`}</h2>
    <p>{`Armeria supports four internal services that are useful for monitoring and management purposes.
You can enable these services by including their service IDs in your `}<inlineCode parentName="p">{`application.yml`}</inlineCode>{` (or `}<inlineCode parentName="p">{`application.properties`}</inlineCode>{`) file.
Service ids are `}<inlineCode parentName="p">{`docs`}</inlineCode>{`, `}<inlineCode parentName="p">{`health`}</inlineCode>{`, `}<inlineCode parentName="p">{`metrics`}</inlineCode>{`, and `}<inlineCode parentName="p">{`actuator`}</inlineCode>{`. You can include one or multiple service IDs based on your requirements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  ports:
    - port: 8080
      protocols:
        - HTTP
  # Add the below configuration
  internal-services:
    include: docs, health, metrics, actuator  # you can add all of the services using \`all\`
    port: 8090
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`armeria.internal-services.port`}</inlineCode>{` configuration is not necessary. If the port is not specified or left as `}<inlineCode parentName="p">{`0`}</inlineCode>{`,
Armeria will automatically bind the internal service to a random unused port.
It is acceptable to set the port of the internal service to be the same as one of the armeria.ports.`}</p>
    <Tip mdxType="Tip">
      <p>{`  The internal services should be configured using the beans described in the documentation.
Avoid directly using the `}<a parentName="p" {...{
          "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
        }}>{`type://ServerBuilder`}</a>{` method with `}<a parentName="p" {...{
          "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
        }}>{`type://ArmeriaServerConfigurator`}</a>{` or `}<inlineCode parentName="p">{`Consumer<ServerBuilder>`}</inlineCode>{` beans,
as they may be overridden by Armeria's automatic configuration.`}</p>
    </Tip>
    <h3 {...{
      "id": "documentation-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#documentation-service",
        "aria-label": "documentation service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Documentation service`}</h3>
    <p>{`Armeria has its own `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`documentation service`}</a>{` that offers useful features.
For instance, it allows you to test RPC protocols in a web browser console, similar to `}<a parentName="p" {...{
        "href": "https://swagger.io/"
      }}>{`Swagger`}</a>{`.`}</p>
    <p>{`Add the service ID `}<inlineCode parentName="p">{`docs`}</inlineCode>{` to `}<inlineCode parentName="p">{`armeria.internal-services.include`}</inlineCode>{` configuration.
The `}<inlineCode parentName="p">{`armeria.docs-path`}</inlineCode>{` is not necessary, as the default path for the documentation service is `}<inlineCode parentName="p">{`/internal/docs`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  internal-services:
    include: docs
  docs-path: /internal/docs
`}</code></pre>
    <p>{`To add custom configuration to your documentation service, you can utilize the `}<a parentName="p" {...{
        "href": "type://DocServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/DocServiceConfigurator.html"
      }}>{`type://DocServiceConfigurator`}</a>{` bean.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public DocServiceConfigurator docServiceConfigurator() {
    return docServiceBuilder -> docServiceBuilder
            .exampleRequests(TodoAnnotatedService.class, "create", "{\\"id\\":\\"42\\", \\"value\\":\\"foo bar\\"}");
}
`}</code></pre>
    <h3 {...{
      "id": "health-check",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#health-check",
        "aria-label": "health check permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Health check`}</h3>
    <p>{`To customize the health check operation, you can utilize the `}<a parentName="p" {...{
        "href": "type://HealthChecker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html"
      }}>{`type://HealthChecker`}</a>{` bean.`}</p>
    <p>{`Add the service ID `}<inlineCode parentName="p">{`health`}</inlineCode>{` to the `}<inlineCode parentName="p">{`armeria.internal-services.include`}</inlineCode>{` configuration.
The `}<inlineCode parentName="p">{`armeria.health-check-path`}</inlineCode>{` is not necessary, as the default path for the health check service is `}<inlineCode parentName="p">{`/internal/healthcheck`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  internal-services:
    include: health
  health-check-path: /internal/healthcheck
`}</code></pre>
    <p>{`You can create a `}<a parentName="p" {...{
        "href": "type://HealthChecker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/healthcheck/HealthChecker.html"
      }}>{`type://HealthChecker`}</a>{` bean that implements your custom health check logic.
For example, the below code determines if the server is healthy using Tomcat connector state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public HealthChecker tomcatConnectorHealthChecker(ServletWebServerApplicationContext applicationContext) {
    final Connector connector = getConnector(applicationContext);
    return () -> connector.getState().isAvailable();
}
`}</code></pre>
    <p>{`You can also add custom configuration to the service using the `}<a parentName="p" {...{
        "href": "type://HealthCheckServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/HealthCheckServiceConfigurator.html"
      }}>{`type://HealthCheckServiceConfigurator`}</a>{` bean, similar to the documentation service.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public HealthCheckServiceConfigurator healthCheckServiceConfigurator() {
    return healthCheckServiceBuilder -> healthCheckServiceBuilder
            .updatable(true)
            .startUnhealthy();
}
`}</code></pre>
    <h3 {...{
      "id": "collecting-metrics",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#collecting-metrics",
        "aria-label": "collecting metrics permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collecting metrics`}</h3>
    <p>{`Armeria provides a `}<a parentName="p" {...{
        "href": "/docs/advanced-metrics"
      }}>{`built-in metric service`}</a>{` using the `}<a parentName="p" {...{
        "href": "https://micrometer.io"
      }}>{`Micrometer`}</a>{` library.
You can expose the collected metrics to various monitoring systems, such as `}<a parentName="p" {...{
        "href": "https://prometheus.io"
      }}>{`Prometheus`}</a>{` or `}<a parentName="p" {...{
        "href": "https://metrics.dropwizard.io/"
      }}>{`Dropwizard`}</a>{`
for comprehensive monitoring and analysis.`}</p>
    <p>{`To include the metrics service, simply add the service ID `}<inlineCode parentName="p">{`metrics`}</inlineCode>{` to the `}<inlineCode parentName="p">{`armeria.internal-services.include`}</inlineCode>{` configuration.
You do not need to specify `}<inlineCode parentName="p">{`armeria.enable-metrics`}</inlineCode>{` or `}<inlineCode parentName="p">{`armeria.metrics-path`}</inlineCode>{` as the default path for the metrics service is `}<inlineCode parentName="p">{`/internal/metrics`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  internal-services:
    include: metrics
  metrics-path: /internal/metrics
  enable-metrics: true  # default is true
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`  The current internal metric service in Armeria supports automatic configuration for Prometheus and Dropwizard monitoring systems.
If you intend to use a different monitoring system, you will need to utilize the `}<a parentName="p" {...{
          "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
        }}>{`type://ArmeriaServerConfigurator`}</a>{` or
`}<inlineCode parentName="p">{`Consumer<ServerBuilder>`}</inlineCode>{` bean and set `}<inlineCode parentName="p">{`armeria.enable-metrics`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{` to disable the auto-configured service.`}</p>
    </Tip>
    <p>{`We will illustrate the bean configuration using an example with the Prometheus monitoring system.
First, create a `}<a parentName="p" {...{
        "href": "https://www.javadoc.io/doc/io.micrometer/micrometer-registry-prometheus/latest/io/micrometer/prometheus/PrometheusMeterRegistry.html"
      }}><inlineCode parentName="a">{`PrometheusMeterRegistry`}</inlineCode></a>{` bean.
If you are using a different monitoring system, create a bean of `}<a parentName="p" {...{
        "href": "https://www.javadoc.io/doc/io.micrometer/micrometer-core/latest/io/micrometer/core/instrument/MeterRegistry.html"
      }}><inlineCode parentName="a">{`MeterRegistry`}</inlineCode></a>{` type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public PrometheusMeterRegistry prometheusMeterRegistry() {
    return new PrometheusMeterRegistry(PrometheusConfig.DEFAULT);
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`  If you are using `}<a parentName="p" {...{
          "href": "https://docs.spring.io/spring-boot/docs/current/actuator-api/htmlsingle/"
        }}>{`Spring Boot Actuator`}</a>{`,
the `}<inlineCode parentName="p">{`PrometheusMeterRegistry`}</inlineCode>{` bean will already be configured by the actuator. Therefore, you should not create the above bean
to avoid potential problems.`}</p>
    </Tip>
    <p>{`To add a prefix to the ID of collected metrics, you can use the `}<a parentName="p" {...{
        "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
      }}>{`type://MeterIdPrefixFunction`}</a>{` bean.
Additionally, you can customize the configuration of the service by utilizing the `}<a parentName="p" {...{
        "href": "type://MetricCollectingServiceConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/MetricCollectingServiceConfigurator.html"
      }}>{`type://MetricCollectingServiceConfigurator`}</a>{` bean.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public MeterIdPrefixFunction meterIdPrefixFunction() {
    return MeterIdPrefixFunction.ofDefault("my.armeria.service");
}

@Bean
public MetricCollectingServiceConfigurator metricCollectingServiceConfigurator() {
    return metricCollectingServiceBuilder -> metricCollectingServiceBuilder
            .successFunction((context, log) -> {
                final int statusCode = log.responseHeaders().status().code();
                // Treat a 404 response as a success
                return statusCode >= 200 && statusCode < 400 || statusCode == 404;
            });
}
`}</code></pre>
    <h3 {...{
      "id": "actuator-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#actuator-support",
        "aria-label": "actuator support permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Actuator support`}</h3>
    <p>{`If you are using `}<a parentName="p" {...{
        "href": "https://docs.spring.io/spring-boot/docs/current/actuator-api/htmlsingle/"
      }}>{`Spring Boot Actuator`}</a>{`,
you can serve it at the `}<inlineCode parentName="p">{`armeria.internal-services.port`}</inlineCode>{`.
The internal services may also be served at the `}<inlineCode parentName="p">{`management.server.port`}</inlineCode>{` if specified.`}</p>
    <p>{`In order to use the actuator service, you need to first add the following dependencies.`}</p>
    <RequiredDependencies dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-spring-boot3-actuator-starter'
    }]} mdxType="RequiredDependencies" />
    <p>{`To include the actuator service, add the service ID `}<inlineCode parentName="p">{`actuator`}</inlineCode>{` to the `}<inlineCode parentName="p">{`armeria.internal-services.include`}</inlineCode>{` configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  internal-services:
    include: actuator
`}</code></pre>
    <h2 {...{
      "id": "other-bean-configurations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-bean-configurations",
        "aria-label": "other bean configurations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other bean configurations`}</h2>
    <p>{`We will provide an explanation of some additional bean configurations that can be helpful to know.`}</p>
    <h3 {...{
      "id": "typearmeriaserverconfigurator-vs-consumerserverbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#typearmeriaserverconfigurator-vs-consumerserverbuilder",
        "aria-label": "typearmeriaserverconfigurator vs consumerserverbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h3" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` vs `}<inlineCode parentName="h3">{`Consumer<ServerBuilder>`}</inlineCode></h3>
    <p>{`You can use both `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` and `}<inlineCode parentName="p">{`Consumer<ServerBuilder>`}</inlineCode>{` to configure the server using `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`.
In fact, both are essentially same functions of `}<inlineCode parentName="p">{`ServerBuilder -> void`}</inlineCode>{`. The most significant difference lies in the order in which they are applied to the server.
Armeria first configures all `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` beans and subsequently applies all `}<inlineCode parentName="p">{`Consumer<ServerBuilder>`}</inlineCode>{` beans.`}</p>
    <p>{`If you have multiple `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` or `}<inlineCode parentName="p">{`Consumer<ServerBuilder>`}</inlineCode>{` beans, you can set the order
using the `}<a parentName="p" {...{
        "href": "https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/core/annotation/Order.html"
      }}>{`@Order`}</a>{` annotation.
It is important to note that the default order of `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` is zero,
and not `}<a parentName="p" {...{
        "href": "https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/core/Ordered.html#LOWEST_PRECEDENCE"
      }}><inlineCode parentName="a">{`Ordered.LOWEST_PRECEDENCE`}</inlineCode></a>{`.`}</p>
    <h3 {...{
      "id": "typedependencyinjector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#typedependencyinjector",
        "aria-label": "typedependencyinjector permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h3" {...{
        "href": "type://DependencyInjector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/DependencyInjector.html"
      }}>{`type://DependencyInjector`}</a></h3>
    <p>{`Armeria provides the ability to manually inject dependencies using `}<a parentName="p" {...{
        "href": "type://DependencyInjector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/DependencyInjector.html"
      }}>{`type://DependencyInjector`}</a>{`.
You can refer to the example in the `}<a parentName="p" {...{
        "href": "/release-notes/1.17.0"
      }}>{`1.17.0 release notes`}</a>{` for more details.`}</p>
    <p>{`Create the `}<a parentName="p" {...{
        "href": "type://DependencyInjector:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/DependencyInjector.html"
      }}>{`type://DependencyInjector`}</a>{` bean, which will replace the default dependency injector.
It's important to note that in this case dependencies that were automatically injected before may not be injected anymore.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=ArmeriaConfiguration.java",
        "filename": "ArmeriaConfiguration.java"
      }}>{`@Bean
public DependencyInjector dependencyInjector() {
    return DependencyInjector.ofSingletons(
            new BadRequestExceptionHandler(),
            new AuthDecorator((ctx, req) ->
                    CompletableFuture.supplyAsync(() -> req.headers().get(AUTHORIZATION).equals("auth-token"))
            )
    );
}
`}</code></pre>
    <p>{`You can also utilize a dependency injector that leverages the `}<a parentName="p" {...{
        "href": "https://docs.spring.io/spring-framework/docs/current/javadoc-api/org/springframework/beans/factory/BeanFactory.html"
      }}><inlineCode parentName="a">{`BeanFactory`}</inlineCode></a>{`
of Spring. After configuring the properties, you can effortlessly create beans to be injected, similar to how you would do it in a Spring-based setup.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "filename=application.yml",
        "filename": "application.yml"
      }}>{`armeria:
  enable-auto-injection: true
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      